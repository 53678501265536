<template>
  <b-card>
    <h4 class="pt-2">
      <feather-icon
          icon="BoxIcon"
          style="width: 18px; height: 18px"
      />
      스포츠 배팅 내역
      <b-badge
          variant="light-primary"
          style="font-size: small; margin-left: 5px; margin-right: 3px"
          class="badge-glow"
      >
        {{ totalRows }}건
      </b-badge>
      <div class="d-inline-flex float-right">
        <b-button
            class=""
            style="margin-right: 0.5rem"
            variant="outline-primary"
            size="sm"
            @click="submit('deleteClientUserBetAll')"
        >
          전체 삭제
        </b-button>
        <b-button
            class=""
            variant="outline-danger"
            size="sm"
            @click="resultUrl"
        >
          라이브 스코어
        </b-button>
      </div>
    </h4>
    <hr>
    <div class="ml-2 mr-2">
      <b-row>
        <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1"
        >
          <label style="width: 41px">상태</label>
          <b-form-select
              id="site-select"
              v-model="fetchSelected1"
              :options="option1"
              class="divSelecter font-small-3"
              size="sm"
          />
        </b-col>
        <!-- Search -->
        <b-col
            cols="12"
            md="6"
        >
          <b-form-group
              class="mb-1"
          >
            <b-input-group size="sm">
              <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
              />
              <b-input-group-append>
                <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
          <!--                </div>-->
        </b-col>
      </b-row>
    </div>
    <div class="ml-2 mr-2">
      <b-row>
        <!-- Per Page -->
        <b-col
            cols="6"
            md="6"
            class="col-sm-12"
        >
          <label>Show</label>
          <b-form-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="pageOptions"
              size="sm"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
          />
        </b-col>
        <b-col
            cols="5"
            lg="5"
            md="5"
            sm="12"
            class=""
        >
          <b-form-group
              class="font-small-3"
              label="배팅일시"
              label-for="period"
              label-cols-md="2"
          >
            <b-input-group
                name="period"
                class="calendar-size font-small-3"
            >
              <b-form-datepicker
                  v-model="periodFrom"
                  class=""
                  boundary="window"
                  locale="ko"
                  placeholder="시작일"
                  aria-controls="example-input"
                  size="sm"
                  :max="periodTo"
              />
              <div class="pl-1 pr-1">
                ~
              </div>
              <b-form-datepicker
                  v-model="periodTo"
                  class=""
                  boundary="window"
                  locale="ko"
                  placeholder="종료일"
                  aria-controls="example-input"
                  size="sm"
                  :min="periodFrom"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col
            class="right col-sm-12"
            cols="1"
            md="1"
            sm="1"
            style="margin-top: 4px"
        >
          <b-form-checkbox
              v-model="isExpandChecked1"
              checked="false"
              class="custom-control-primary float-right"
              name="check-button"
              switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CornerRightDownIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="CropIcon" />
            </span>
          </b-form-checkbox>
        </b-col>
      </b-row>
    </div>
    <b-table
        responsive
        show-empty
        hover
        small
        empty-text="No matching records found"
        :per-page="perPage"
        :items="fetchClientUserBetHistory"
        :fields="fields1"
        :filter="filter"
        :filter-included-fields="filterOn"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        style="text-align: center; font-size: small"
        :current-page="currentPage"
        @filtered="onFiltered"
        @row-clicked="rowClickHandler1"
    >
      <template
          #cell(index)="data"
          size="sm"
          style="text-align: center; min-width: 3rem"
      >
        {{ totalRows - (perPage * (currentPage - 1)) - data.index }}
      </template>
      <template #cell(requestId)="data">
        <div
            size="sm"
            style="text-align: center; min-width: 5rem"
        >
          {{ data.item.requestId }}({{ data.item.requestNickname }})
        </div>
      </template>
      <template #cell(category)="data">
        <div
            size="sm"
            style="text-align: center; min-width: 5rem"
        >
          {{ categoryText(data.item.category) }}
        </div>
      </template>
      <template #cell(betDate)="data">
        <div
            size="sm"
            style="text-align: center; min-width: 10rem"
        >
          {{ data.item.betDate }}
        </div>
      </template>
      <template #cell(sports)="data">
        <div style="text-align: center; min-width: 4rem;">
          {{ data.item.sports }}...
        </div>
      </template>
      <template #cell(fold)="data">
        <div
            size="sm"
            style="text-align: center; min-width: 2rem"
        >
          {{ data.item.fold }}
        </div>
      </template>
      <template #cell(betCash)="data">
        <div
            size="sm"
            style="text-align: right; min-width: 4rem; color:rgb(196,186,138)"
        >
          {{ Number(data.item.betCash).toLocaleString() }}
        </div>
      </template>
      <template #cell(totalOdds)="data">
        <div
            size="sm"
            style="text-align: right; min-width: 3rem; color:rgb(196,186,138)"
        >
          {{ Number(data.item.totalOdds).toLocaleString() }}
        </div>
      </template>
      <template #cell(totalHitCash)="data">
        <div
            size="sm"
            style="text-align: right; min-width: 4rem; color:rgb(196,186,138)"
        >
          {{ Number(data.item.totalHitCash).toLocaleString() }}
        </div>
      </template>
      <template #cell(betResult)="data">
        <div
            v-if="data.item.betResult === 'WIN'"
            size="sm"
            style="text-align: center; min-width: 4rem; color: limegreen"
        >
          적중
        </div>
        <div
            v-else-if="data.item.betResult === 'LOSS'"
            size="sm"
            style="text-align: center; min-width: 4rem; color: red"
        >
          미적중
        </div>
        <div
            v-else-if="data.item.betResult === null"
            size="sm"
            style="text-align: center; min-width: 4rem; color: dodgerblue"
        >
          결과대기
        </div>
        <div
            v-else-if="data.item.betResult === 'ALL TIE'"
            size="sm"
            style="text-align: center; min-width: 5rem; color: yellow; font-size: small"
        >
          배팅금 반환
        </div>
        <div
            v-else-if="data.item.betResult === 'CUSTOM CANCELED'"
            size="sm"
            style="text-align: center; min-width: 5rem; color: yellow; font-size: small"
        >
          배팅금 반환
        </div>
        <div
            v-else-if="data.item.betResult === 'USER CANCELED'"
            size="sm"
            style="text-align: center; min-width: 5rem; color: yellow; font-size: small"
        >
          배팅금 반환
        </div>
      </template>
      <template #cell(delete)="data">
        <div
            size="sm"
            style="text-align: center; min-width: 2rem; color:rgb(196,186,138)"
        >
          <b-button
              size="sm"
              variant="primary"
              style="font-size: x-small"
              @click="submit('deleteClientUserBetItem', data.item.groupId)"
          >
            삭제
          </b-button>
        </div>
      </template>
      <template #row-details="row">
        <b-card
            class="p-0"
            style="background-color: #212026;"
        >
          <RefundHitResultDetails :record="row.item._detailsData" @refreshParent="refreshParentMethod"/>
        </b-card>
      </template>
    </b-table>
    <!--          <pre>selected: {{ selectedGroupIds }}</pre>-->
    <div class="mx-2 mb-2">
      <b-row>
        <!-- Pagination -->
        <b-col
            cols="12"
            sm="12"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BTable,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BPagination,
  BFormSelect,
  BInputGroupAppend,
  BInputGroup,
  BBadge,
  BFormCheckbox,
  BFormDatepicker, VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import Swal from 'sweetalert2'

import { FETCH_CLIENT_USER_BET_HISTORY, FETCH_GAME_SET_BASIC, FETCH_GAME_CASINO_TRANSACTION } from '@/store/result/action'
import { DELETE_CLIENT_USER_BET_ITEM, DELETE_CLIENT_USER_BET_ALL } from '@/store/result/mutation'

import * as moment from 'moment-timezone'
import RefundHitResultDetails from '@/views/ResultDetails'

moment().tz('Asia/Seoul')
const fmt1 = 'YYYY-MM-DD HH:mm'
const fmt2 = 'YYYYMMDD'

import { createNamespacedHelpers } from 'vuex'
const authStore = createNamespacedHelpers('authStore')
const resultStore = createNamespacedHelpers('resultStore')

export default {
  components: {
    RefundHitResultDetails,
    BTabs,
    BTab,
    BRow,
    BCard,
    BTable,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BPagination,
    BFormSelect,
    BInputGroupAppend,
    BInputGroup,
    BBadge,
    BFormCheckbox,
    BFormDatepicker,
    VBTooltip,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    const today = moment().format('YYYY-MM-DD')
    const oneWeekAgo = moment().subtract(2, 'days').format('YYYY-MM-DD')
    return {
      tab: '',
      userData: JSON.parse(localStorage.getItem('userData')),
      userIp: localStorage.getItem('ip'),
      fetchSelected1: '',
      fetchSelected2: '',
      option1: [
        { value: 'ALL', text: '전체' },
        { value: 'YET', text: '미결과' },
        { value: 'NONE', text: '미적중' },
        { value: 'CONFIRMED', text: '정산완료' },
      ],
      option2: [
        { value: 'Sports-Prematch', text: '프리매치' },
        { value: 'Sports-Special', text: '스페셜' },
        { value: 'Sports-Inplay', text: '인플레이' },
      ],
      option3: [
        { value: 'Match Winner', text: '승무패' },
        { value: 'Home/Away', text: '승무패' },
        { value: 'Asian Handicap', text: '핸디캡' },
        { value: 'Asian Handicap (Games)', text: '핸디캡' },
        { value: 'Maps Handicap', text: '핸디캡' },
        { value: 'Goals Over/Under', text: '오버언더' },
        { value: 'Over/Under', text: '오버언더' },
        { value: 'Over/Under by Games in Match', text: '오버언더' },
        { value: 'Total Maps', text: '오버언더' },
      ],
      perPage: 10,
      pageOptions: [10, 20, 30],
      totalRows: 0,
      notReadCnt: 0,
      currentPage: 1,
      sortBy: 'idx',
      sortDesc: true,
      sortDirection: 'desc',
      sortByFinish: 'idx',
      sortDescFinish: true,
      sortDirectionFinish: 'desc',
      filter: null,
      filterOn: [],
      disabled: false,
      check1: 'false',
      isHidden1: true,
      selectedRecord: null, // 선택된 record를 저장하기 위한 변수
      isExpandChecked1: false,
      isExpandChecked2: false,
      periodFrom: moment(new Date()).startOf('month').format(fmt1),
      periodTo: moment(new Date()).endOf('month').format(fmt1),
      selectedGroupIds: [],
      selectedCash: 0,
      fields1: [
        {
          key: 'index', sortable: false, label: 'No.', thClass: 'col1',
        },
        {
          key: 'requestId', sortable: false, label: '아이디(닉네임)', thClass: 'col5',
        },
        {
          key: 'betDate', sortable: false, label: '배팅일시', thClass: 'col3',
        },
        {
          key: 'category', sortable: false, label: '카테고리', thClass: 'col2',
        },
        {
          key: 'sports', sortable: false, label: '구분', thClass: 'col2',
        },
        {
          key: 'fold', sortable: false, label: '폴드', thClass: 'col3',
        },
        {
          key: 'betCash', sortable: false, label: '배팅금액', thClass: 'col3',
        },
        {
          key: 'totalOdds', sortable: false, label: '총배당', thClass: 'col3',
        },
        {
          key: 'totalHitCash', sortable: false, label: '적중금액', thClass: 'col3',
        },
        {
          key: 'betResult', sortable: false, label: '적중', thClass: 'col3',
        },
        {
          key: 'delete', sortable: false, label: '삭제', thClass: 'col3',
        },
      ],
    }
  },
  watch: {
    isExpandChecked1(newVal) {
      this.fetchClientUserBetHistory.forEach(item => {
        this.$set(item, '_showDetails', newVal)
        this.$set(item, '_detailsData', item) // 각 항목의 상세 데이터를 설정
      })
    },
    fetchSelected1(newVal) {
      // When the selected option changes, fetch the data again with the new value
      this.fetchSelected1 = newVal
      this.fetchData1(newVal)
    },
    fetchSelected2(newVal) {
      // When the selected option changes, fetch the data again with the new value
      this.fetchSelected2 = newVal
      this.fetchData2(newVal)
    },
    periodFrom() {
      this.fetchData1(this.fetchSelected1)
    },
    periodTo() {
      this.fetchData1(this.fetchSelected1)
    },
    periodFrom2() {
      this.fetchData2(this.fetchSelected2)
    },
    periodTo2() {
      this.fetchData2(this.fetchSelected2)
    },
  },
  created() {
    // this.init()
  },
  computed: {
    ...authStore.mapGetters([
      'user',
    ]),
    ...resultStore.mapGetters({
      fetchClientUserBetHistory: 'fetchClientUserBetHistory',
      deleteClientUserBetAll: 'deleteClientUserBetAll',
      deleteClientUserBetItem: 'deleteClientUserBetItem',
    }),
    oddsTypeText() {
      return value => {
        const option = this.option3.find(option => option.value === value)
        return option ? option.text : value
      }
    },
    categoryText() {
      return value => {
        const option = this.option2.find(option => option.value === value)
        return option ? option.text : value
      }
    },
  },
  mounted() {
    this.fetchSelected1 = 'ALL'
    this.isExpandChecked1 = true
  },
  beforeDestroy() {
    this.$socket.emit('handleMenuEntry', {
      site: this.$site,
      userid: JSON.parse(localStorage.getItem('userData')).userid,
      menu: '',
      menuCategory: '',
    })
  },
  methods: {
    ...resultStore.mapActions({
      $fetchClientUserBetHistory: FETCH_CLIENT_USER_BET_HISTORY,
      $fetchClientGameSetBasic: FETCH_GAME_SET_BASIC,
      $deleteClientUserBetAll: DELETE_CLIENT_USER_BET_ALL,
      $deleteClientUserBetItem: DELETE_CLIENT_USER_BET_ITEM,
    }),
    async fetchData1(fetchSelected) {
      const periodFrom = this.periodFrom ? `${moment(this.periodFrom).format('YYYY-MM-DD')} 00:00:00` : ''
      const periodTo = this.periodTo ? `${moment(this.periodTo).format('YYYY-MM-DD')} 23:59:59` : ''
      this.$socket.emit('handleMenuEntry', {
        site: this.$site,
        userid: this.userData.userid,
        menu: 'game-result',
        menuCategory: '',
      })
      await this.$fetchClientUserBetHistory({
        site: this.$site,
        userid: this.userData.userid,
        confirmed: fetchSelected,
        periodFrom,
        periodTo,
      })
      // console.log(this.fetchClientUserBetHistory.length)
      this.totalRows = this.fetchClientUserBetHistory.length

      // 데이터를 다 가져온 후 각 항목에 대한 펼침 상태 설정
      if (this.isExpandChecked1 === true) {
        this.fetchClientUserBetHistory.forEach(item => {
          this.$set(item, '_showDetails', this.isExpandChecked1) // isExpandChecked1 값에 따라 펼침/오므림 설정
          this.$set(item, '_detailsData', item) // 각 항목의 상세 데이터를 설정
        })
      }
    },
    // submit
    async submit(target, data) {
      await Swal.fire({
        title: '변경내용을 저장 하시겠습니까?',
        text: data,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        background: 'rgb(3,11,37)',
        customClass: {
          cancelButton: 'btn btn-dark',
          confirmButton: 'btn btn-primary mr-1',
        },
      }).then(async result => {
        if (result.isConfirmed) {
          await this.confirmYes(target, data)
        }
      })
    },
    // confirm Yes
    async confirmYes(target, data) {
      // const mySite = this.userData.site
      // const myId = this.userData.userid
      try {
        if (target === 'deleteClientUserBetAll') {
          console.log(data)
          await this.$deleteClientUserBetAll()
        }
        if (target === 'deleteClientUserBetItem') {
          console.log(data)
          await this.$deleteClientUserBetItem({
            groupId: data,
          })
        }
      } catch {
        console.error()
      } finally {
        Swal.fire({
          title: '저장 완료',
          icon: 'success',
          timer: 5000,
          background: 'rgb(3,11,37)',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
        }).then(result => {
          // const args = { site: mySite }
          this.fetchData1(this.fetchSelected1)
        })
      }
    },
    async rowClickHandler1(record, index, type) {
      // console.log(type.target)
      // if (type.target.tagName === 'DIV') {
      //   return // <span> 태그 클릭시 함수 실행 중지
      // }
      // if (type.target.ariaColIndex !== '1' && type.target.tagName !== 'DIV') {
      if (type.target.ariaColIndex !== '1') {
        this.$set(record, '_showDetails', !record._showDetails) // _showDetails 설정
        this.$set(record, '_detailsData', record) // 각 항목의 상세 데이터를 설정
      }
    },
    rowClickHandler2(record, index) {
      // console.log('rowClickHandler2 : ', record)
      // console.log(index)
      // console.log(type.srcElement.parentElement.ariaColIndex)
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    onFiltered2(filteredItems2) {
      this.totalRows2 = filteredItems2.length
      this.currentPage2 = 1
    },
    getTotal(field) {
      return this.fetchClientUserBetHistory.reduce((acc, item) => acc + item[field], 0)
    },
    getTotal2(field) {
      return this.fetchGameCasinoTransaction.reduce((acc, item) => acc + item[field], 0)
    },
    toggleAllRows() {
      if (this.allSelected) {
        this.selectedGroupIds = this.fetchClientUserBetHistory.map(item => item.groupId)
      } else {
        this.selectedGroupIds = []
      }
    },
    refreshParentMethod() {
      // 부모 컴포넌트를 리프레시하는 로직을 여기에 작성합니다
      console.log('Parent component refreshed')
      this.fetchData1() // 예시: 데이터 로드 메서드 호출
    },
    updateTotalSum(totalHitCash, groupId) {
      if (this.selectedGroupIds.includes(groupId)) {
        // groupId가 selectedGroupIds 배열에 있으면 체크된 상태
        this.selectedCash += totalHitCash
      } else {
        // 그렇지 않으면 체크 해제된 상태
        this.selectedCash -= totalHitCash
      }
    },
    truncateText(text, maxLength) {
      if (!text) return
      if (text.length <= maxLength) {
        return text
      }
      return `${text.substring(0, maxLength)}...`
    },
    resultUrl() {
      const resultUrl = 'https://kr.7msport.com/'
      window.open(resultUrl)
    },
  },
}

</script>

<style scoped>
.per-page-selector {
  width: 90px;
}
.divSelecter {
  width: 180px;
}
</style>
